<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage finance transaction histories</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Finance transaction histories
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn  @click="exportExcel()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-file-excel</v-icon>&nbsp;
                     Export
                    </v-btn>
                    <v-btn  @click="addIncome()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-add</v-icon>&nbsp;
                     Add income
                    </v-btn>
                    <v-btn  @click="addExpense()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-add</v-icon>&nbsp;
                     Add expense
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body ">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="4">
                    <v-text-field label="Payer name, phone, email, transaction id"
                                  type="text"
                                  dense
                                  outlined v-on:keyup.enter="getAllHistories()"
                                  v-model="search.name"/>
                  </v-col>
                  
                  <v-col cols="12" md="4">
                    <v-select label="Payment method" outlined dense :items="payment_methods"
                              item-text="title"
                              item-value="value" v-on:keyup.enter="getAllHistories()"
                              v-model="search.payment_method"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuStartDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.start_date"
                            label="View from date"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.start_date"
                          outlined
                          dense
                          no-title
                          @input="menuStartDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuEndDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.end_date"
                            label="View up to date"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.end_date"
                          outlined
                          dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                          no-title
                          @input="menuEndDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete  v-model="search.month" outlined label="Months"
                                     item-text="text" :items="months"
                                     item-value="value"   v-on:keyup.enter="getAllHistories()"
                                     dense
                                     clearable>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select label="Type" outlined dense :items="statuses" item-text="title"
                              item-value="value"   v-on:keyup.enter="getAllHistories()"
                              v-model="search.status"></v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="text-right">
                    <v-btn
                        @click="getAllHistories()"
                        class="btn btn-primary btn-search w-35"

                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <table class="table">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Paid for</th>
                    <th class="px-3 wrap-column">Payer name</th>
                    <th class="px-3 wrap-column">Payer phone</th>
                    <th class="px-3 wrap-column">Payer relation</th>
                    <th class="px-3 wrap-column">GST</th>
                    <th class="px-3 wrap-column">Discount</th>
                    <th class="px-3 wrap-column">Total amount</th>
                    <th class="px-3 wrap-column">Paid amount</th>
                    <th class="px-3 wrap-column">Paid date</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-show="transaction_histories.length > 0" v-for="(history, index) of transaction_histories" :key="index">
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium" :class="history.type == 'expense' ? 'text-danger' : 'text-success'">
                         {{ history.type.toUpperCase() }}
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        {{ history.paid_for || 'Other' }}
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_name }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_mobile }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_relation_text }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">Rs.{{ history.gst_amount || '0.00' }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">Rs.{{ history.discount_amount || '0.00' }}</span>
                    </td>
                    <td class="px-3 wrap-column"><span class="font-weight-medium">Rs.{{ history.total_amount }}</span></td>
                    <td class="px-3 wrap-column"> <span class="font-weight-medium">Rs.{{ history.is_quote ? '0.00' : history.paid_amount }}</span></td>
                    <td class="px-3 wrap-column"> <span class="font-weight-medium">{{ history.payment_date }}</span></td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="lockedHistory(history)" class="navi-link">
                                <span class="navi-icon">
                                  <i :class="history.is_locked?'fas fa-lock':'fas fa-lock-open'"></i>
                                </span>
                                <span class="navi-text">{{ history.is_locked?'Locked':'Unlocked' }}</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.is_quote && history.payment_status == 'pending'">
                              <a @click="markAsPaid(history)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-circle-check"></i>
                                </span>
                                <span class="navi-text">Mark as paid</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.invoice_id">
                              <a @click="openInvoice(history.invoice_id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">Generate invoice</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.invoice_id && history.invoice_generate">
                              <a @click="sendInvoice(history.invoice_id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-receipt"></i>
                                </span>
                                <span class="navi-text">Send invoice</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.is_quote">
                              <a @click="editHistory(history)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                    <!--                  <td>{{history.payer_relation}}</td>-->
                  </tr>
                  <tr v-if="transaction_histories.length == 0">
                    <td colspan="12" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination  v-if="transaction_histories.length > 0"
                               @input="getAllHistories"  class="pull-right mt-7"
                               v-model="page"
                               :total-rows="total"
                               :per-page="perPage"
                               first-number
                               last-number
                ></b-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import FinanceMixin from "@/mixins/FinanceMixin";
import FinancialHistoryService from "@/services/finance/history/FinancialHistoryService";
import InvoiceService from "@/services/invoice/InvoiceService";
import FinancialHistoryItemService from "@/services/finance/history/item/FinancialHistoryItemService";

const invoice = new InvoiceService();
const historyService = new FinancialHistoryService();
const itemService = new FinancialHistoryItemService();

export default {
  name: "financial_history",
  mixins: [ FinanceMixin],
  data() {
    return {
      transaction_histories: [],
      search: {
        title: null,
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      payment_methods: [
        {
          title: 'Bank',
          value: 'bank'
        },
        {
          title: 'Cash',
          value: 'cash'
        },
        {
          title: 'Khalti',
          value: 'khalti'
        },
        {
          title: 'Esewa',
          value: 'esewa'
        },
        {
          title: 'Connect Ips',
          value: 'connect_ips'
        },

      ],
      statuses: [
        {title: "Income", value: 'income'},
        {title: "Expense", value: 'expense'},
      ],
      total: null,
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
    };
  },
  mounted() {
   this.getAllHistories()
  },
  methods: {

    getAllHistories(){
      this.getHistories(this.search, this.page);
    },

    detail(id) {
      this.$router.push({name: "transaction-photo", params: {id: id}});
    },

    reset() {
      this.search = {}
      this.getHistories(this.search, this.page);
    },

    exportExcel() {
      historyService.exportExcel(this.search);
    },

    addIncome(){
      this.$router.push({name: 'finance-history-type', params: {type: 'income'}})
    },

    addExpense(){
      this.$router.push({name: 'finance-history-type', params: {type: 'expense'}})
    },

    editHistory(history) {
      this.$tabs.close().then(response=>{
        this.$router.push({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
      })
    },

    openInvoice(invoiceId) {
      this.$router.push({name: 'finance-history-invoice', params: {id:invoiceId}})
    },

    markAsPaid(history) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.getHistoryItems(history);
          }
        },
      });
    },

    getHistoryItems(history) {
      itemService.getAllByHistory(history.id).then((response) => {
        history.items = response.data.data;
        history.is_quote = 0;
        history.payment_status = 'paid';
        this.updateHistory(history);
      });
    },

    updateHistory(history) {
      historyService
          .update(history.id, history)
          .then((response) => {
            if(response.data.status == "OK") {
              this.$snotify.success("Paid successfully");
            } else {
              this.$snotify.error("Oops something went wrong");
            }
          })
          .catch(() => {
            this.$snotify.error("Oops something went wrong");
          });
    },


    sendInvoice(invoiceId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            invoice
                .sendInvoice(invoiceId)
                .then((response) => {
                  if(response.data.status == "OK") {
                    this.$snotify.success("Send invoice successfully ");
                  } else {
                    this.$snotify.error("Oops something went wrong");
                  }
                })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    lockedHistory(history) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data =  {
              is_locked: !history.is_locked
            };
            historyService
                .updateData(history.id, data)
                .then(() => {
                  this.reset();
                  this.$snotify.success("Update Successfully ");
                })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
};
</script>
