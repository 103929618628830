import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class FinancialHistoryItemService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/finance/history`;
    }
    getAllByHistory(historyId) {
        let url = `${this.#api}/${historyId}/item`;
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}/item`
        return apiService.delete(url);
    }

}